<template>
  <section :class="$style.seo">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['question']"
          @querySearch="querySearch"
          @selectItem="handleSelectSeo"
          @handleFindItems="handleFindSeos"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button
        type="primary"
        @click="
          $router.push(
            getRoute({
              route: $options.ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.CREATE,
            }),
          )
        "
      >
        Создать SEO-FAQ
      </el-button>
    </div>
    <el-table :data="seoFAQs" stripe>
      <el-table-column prop="question" label="Вопрос"> </el-table-column>
      <el-table-column
        prop="answer"
        label="Ответ"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div :class="$style.answer" v-html="scope.row.answer"></div>
        </template>
      </el-table-column>

      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.EDIT,
                params: { id: String(scope.row.id) },
              })
            "
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import notifications from '@/mixins/notifications'
import { PROJECTS } from '@/constants/projects/index.js'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'
export default {
  ADDSELLERS_ADMIN_ROUTES,
  components: {
    ActionButtons,
    Autocomplete,
  },
  mixins: [notifications],
  data() {
    return {
      seoFAQs: [],
      limit: 20,
      page: 1,
      total: 10,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        project: PROJECTS.ADDSELLERS,
      }

      if (this.search) {
        query.search = this.search
      }
      const { value, error } =
        await delivery.ContentServiceCore.SeoFaqsActions.getList(query)
      loading.close()
      if (error) {
        return
      }

      this.seoFAQs = value.data
      this.total = value.meta.count
    },

    async handleFindSeos() {
      await this.getList()
    },
    handleSelectSeo(selectedSeo) {
      this.$router.push(
        this.getRoute({
          route: ADDSELLERS_ADMIN_ROUTES.CONTENT.SEOFAQS.EDIT,
          params: { id: selectedSeo.id },
        }),
      )
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ContentServiceCore.SeoFaqsActions.getList({
          limit: this.limit,
          page: this.page,
          project: PROJECTS.ADDSELLERS,
          search: queryString,
        })

      if (error) return
      setSearchItems(value.data)
    },
    async remove(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.question}?`)
      if (isConfirm) {
        const res = await delivery.ContentServiceCore.SeoFaqsActions.delete(
          data.id,
        )
        if (res.error) return
        this.showNotification('SEO-FAQ успешно удален', 'success')
        this.getList()
      }
    },
    getRoute({ route, site, params }) {
      return getRoute({ route, site, params })
    },
  },
}
</script>

<style lang="scss" module>
.seo {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-bottom: 0;
      border-radius: 0;
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }

  .answer {
    display: flex;
    flex-direction: column;
    max-height: 1.4375rem;
  }
  .pagination {
    @include pagination;
  }
}
</style>
